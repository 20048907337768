import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {FormsModule} from '@angular/forms'
import {ActivatedRoute, Router} from '@angular/router'
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AboutMeComponent } from './about-me/about-me.component';
import { ResumeComponent } from './resume/resume.component';
import { ProfessionalComponent } from './professional/professional.component';
import { ContactMeComponent } from './contact-me/contact-me.component';
import { FootbarComponent } from './footbar/footbar.component';
import { TopbarComponent } from './topbar/topbar.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {NgForm} from '@angular/forms';


@NgModule({
  declarations: [
    AppComponent,
    AboutMeComponent,
    ResumeComponent,
    ProfessionalComponent,
    ContactMeComponent,
    FootbarComponent,
    TopbarComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
     BrowserAnimationsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
