<div id="topnav">
  <app-topbar></app-topbar>
</div>

<div id="middle">
<router-outlet></router-outlet>
</div>

<div id="footnav">
  <app-footbar></app-footbar>

</div>