
<div id="foo">
   <!-- <a href="https://aplaceformetoorganizemythoughts.wordpress.com"> <img src="/assets/img/wordpress-icon.svg"></a>
-->

    <a href="https://www.linkedin.com/in/aishwarya-karad-76bb25191/"><img src="/assets/img/linkedin-icon.svg" ></a>

   <!-- <a href="https://aishwaryakarad.academia.edu">
      <img src="/assets/img/academia-edu-vector-logo.svg">
    </a>
  -->

    <a href="https://github.com/aishwaryakarad">
      <img src="/assets/img/github.png">
    </a>
    <a href="mailto:aish.karad97@gmail.com">
      <img src="/assets/img/email.png">
    </a>
  </div> 
