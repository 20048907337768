import { Component, OnInit } from '@angular/core';
import {FormsModule} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-contact-me',
  templateUrl: './contact-me.component.html',
  styleUrls: ['./contact-me.component.css']
})
export class ContactMeComponent implements OnInit {
	emo:string='';
	public msg:string='';
	lname:string='';
	fname:string='';
	msg1:string='';


  constructor(
  private route: ActivatedRoute,
  private router: Router,) {

  

  }

  ngOnInit(): void {
  }

  onSubmit() : void
  {
  		this.msg1=`The fastest way to contact me would be via LinkedIn.If you don't have LinkedIn, then you can email me at amkarad[at]ncsu.edu. Here is the message you typed: ${this.msg}`;

  		alert("ERROR 72937: You Have been Pranked. ;)");

  		
  }

	 


}


