
<body>
<h3>Do what you gotta do: </h3>
<form (ngSubmit)="onSubmit()">
	<label for="fname">First Name:</label>
	<input type="text" name="fname">
	<br>
	<label for="lname">Last Name:</label>
	<input type="text" required="required" name="lname" [(ngModel)]="lname">
	<br>
	<label for="emo">Email:</label>
	<input type="email" required="required" name="emo" [(ngModel)]="emo">
	<br>
	<label for="msg">Message:</label>
	<textarea name="msg" [(ngModel)]="msg" style="height: 350px" required="required"></textarea>
	<button type="Submit">Submit</button>

	<p name="msg1" [(ngModel)]="msg1"> {{msg1}}</p>


</form>
</body>