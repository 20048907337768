

<body class="box">
	<br>
	<br>
	<br>
<br>

<div id="btn2">
<button (click)= "educlick()" id="btn1">Education</button>
</div>
<br>
	
<div *ngIf="disp"  id="content">
	<ul>
				
			
			<li>North Carolina State University Master's in Computer Networking     GPA:3.3/4</li>

			<li>Savitribai Phule Pune University Bachelor's in Engineering in Computer Enginnering GPA:8.9/10</li>

			<li>PowerMBA</li>

			<li>St. Mary's Jr. College for Physics, Chemistry, Mathematics, Biology and English 2013-2015   ISC score: 90%</li>

			<li>St. Mary's School   2002-2013 ICSE score:95%</li>


	</ul>

</div>
<br>
<br>
<div id="btn2">
<button (click)= "toolclick()">Tools and Technologies</button>
</div>

<br>
<div *ngIf="show" id="content" >
	
	
	<img src="../assets/img/c.svg">
	<img src="../assets/img/python-logo-inkscape.svg">
	<img src="../assets/img/java-icon.svg">
	<img src="../assets/img/matlab_icon.svg">
	<img src="../assets/img/mysql.svg">
	<img src="../assets/img/css.svg">
	<img src="../assets/img/angular-icon.svg">
	<img src="../assets/img/github.png">
	<img src="../assets/img/google_cloud.svg">
	
	<img src="../assets/img/putty_icon.png">
	<br>
	<img src="../assets/img/html-5.svg">
	
	<img src="../assets/img/aws2.svg">
	<img src="../assets/img/docker-ar21.svg">
	<img src="../assets/img/wireshark.svg">
	
	<img src="../assets/img/visual-studio-code.svg">
	<img src="../assets/img/eclipse.svg">
</div>
<br>
<br>
	<div id="btn2">

	<button (click)= "whyclick()" >Why Programming?</button>
	</div>
	<br>
	<div *ngIf= "prog"  id="content">
	<p>I was in the 8th grade when my school actually introduced computers and programming to the school. I didn't really know much about it but they said only kids who passed the aptitude test would be eligible to opt for it(probably because they only had a limited number of computers and far more students). I ended up giving the test and qualifying for the class and that was when I was first introduced to the world of programming through Java. I absolutely loved it because not only did I do very well in it but it was also a new and exciting experience. The following year, I decided to join an after school robotics team which we called "ElectroHephathenix" and I was able to work with some electronics, mechanical parts and code. I recall That's when I think I made up my mind to be an Engineer.  </p>
</div>
<br>
<br>

<div id="btn2">
<button (click)= "projclick()">Projects</button>
</div>

	
	<div *ngIf="proj">
			<div class="flex-container">
				<div>
				<h3>
					<a href="https://github.com/aishwaryakarad/OSPF">
						OSPF network automated creation and verification
					</a>
				</h3> 
				<p>This involved the automation of assigning OSPF areas to different nodes in a network. </p>
				 </div>
				<div> 
				<h3>
					<a href="https://github.com/aishwaryakarad/ParkingLotSQL">Database design for Parking Lot</a>
				</h3>	<p>We designed and deployed a database system for a parking lot and for parking tickets. We used MySQL and Python for this. This included issuing permits for visitors, students and employees. It also included issuing citations and storing vehicular information.</p>
				</div>
				
				<div> <h3>
					<a href="https://github.com/aishwaryakarad/cache-coherence">Simulation of Cache and Cache coherence protocols</a>
				</h3>
					<p>I used C++ to simulate how caches operate and tested various cache coherence protocols such as MSI, MESI, Dragon. I also tested the performance of these protocols for varying loads</p></div>
				<div> <h3>
					<a href="https://github.com/aishwaryakarad/P2P-Quic-TCP">Peer to Peer file sharing with distributed indexing</a>
				</h3>
					<p>We designed and implemented a peer to peer file sharing application with distributed indexing of the available files in the network. It included addition and deletion devices and files. The propagation of these changes in the entire network. We implemented this using TCP/IP and the QUIC protocol to compare the performance of both. QUIC is a UDP based protocol that aims to provide the same reliability as TCP. </p>
				</div>
				<div><h3>
					<a href="https://github.com/aishwaryakarad/parallel_radix_sort">Parallel Radix sort</a>
				</h3>
					<p>I implemented a parallel radix sort using multithreading in C++ to achieve scalability for large datasets. I also tested the performance of my implementation using different datasets</p>
				</div>
				<div><h3>
					<a href="https://github.com/aishwaryakarad/TaintAnalysisofApps">Taint analysis of apps</a>
				</h3>
					<p>I used a static analysis tool called Argus-SAF to analyze flow of information in various APKs. I used Google Compute Engine on their Cloud Platform for running this heavy tool on 25 apks. This generated a set of tainted paths. This showed how information flowed through various components in the application and information shared across a network. This was used to identify any information that was collected by the application developers without knowledge of the user. </p>
				</div>
				<div>
					<h3>Passive Network audit</h3>
					<p> I developed python scripts that would run over the censys.io database to collect information on the type of traffic found, common vulnerabilties in the network and if any major threats were found. This also included suggestions to improve the security of the network from possible threats and vulnerabilities found.</p>
				</div>
				<div>
					<h3>
						<a href="https://github.com/knl-kolhe/BE-Project">Simplifying Online Transactions</a>
					</h3>
					<p>This was my senior design project and I worked to manage a team of 4 to develop an application to authenticate online transactions using Facial recognition. We used liveliness detection to ensure that it was a real person, this solution was aimed to be used with camera's with not very high resolution or depth perception features. I used OpenCV and Tensorflow to perform Optical Character Recognition to read information on credit/debit cards. We used SHA-256 to encrypt the data sent from the user to the bank's database for verification. We published 2 research papers for this and even won an award for our second paper at the SciencePlus Conference! </p>
				</div>
			</div>

	</div>
	
	<br>
	<br>

<div id="btn2">
	<button >
		<a href="/assets/img/resume.pdf" target="_blank" id="res">Resume </a></button>

	
</div>
<!--
<div *ngIf="res" id="content">
	<a href="/assets/img/resume.pdf" target="_blank">Click here to view resume in a new tab</a>

	
</div>
-->
<br>
<br>
<br>
<br>
<br>
<br>
</body>
	
			
