<body>
	<br>
	<br>
	<br>
	<br>
	<br>
	<br>
	<h1>Hello! </h1>
	<br>
	<h2>My name is Aishwarya Karad and I'm a Computer Engineer</h2>
	<br>
	<div>
	<img  class="norm" src="../assets/img/aish.png">
	</div>
	<br>
	<br>

	<h3> A little something about me</h3>
	<br>
	<div id="p1">I come from a city called Pune in India. I currently reside in Raleigh,NC. I like designing applications and trying to figure out how stuff works. Other than programmibng, I like history, books, painting and the great outdoors. I used to play tennis competitively. I'm also competitive when I play board games or cards (a pandemic lockdown staple at this point). This website was inspired by a lot of vaporwave artwork and I would appreciate your feedback. You can contact me via LinkedIn or email!  </div>

	
	
	
	
		<br>
		<br>

</body>