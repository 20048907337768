import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AppComponent} from './app.component';
import { AboutMeComponent } from './about-me/about-me.component';
import {ResumeComponent} from './resume/resume.component';
import {ProfessionalComponent} from './professional/professional.component';
import {ContactMeComponent} from './contact-me/contact-me.component';
import {FootbarComponent} from './footbar/footbar.component';
import { TopbarComponent } from './topbar/topbar.component';



const routes: Routes = [
{ path: 'about-me', component: AboutMeComponent },
{path:'resume', component: ResumeComponent},
{path:'professional', component: ProfessionalComponent},
{path: 'app' ,component: AppComponent},
{path:'contact-me', component:ContactMeComponent},
{path:'footbar', component:FootbarComponent},
{path:'topbar', component:TopbarComponent}];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
