<div id="centered">
	
		
		<a routerLink="professional">
			
			<figure><img src="../assets/img/comp.png" alt="Professional">
				<figcaption>Professional</figcaption>
			</figure>
				

			

			</a>
		<a routerLink="about-me">
			<figure>	<img src="../assets/img/head.png" alt="About Me">
			<figcaption>About Me</figcaption>
			</figure>
		
				
		</a>
		<!--
		<a routerLink="contact-me">Contact Me</a>
		
		-->
	
</div>
