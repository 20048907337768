import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-professional',
  templateUrl: './professional.component.html',
  styleUrls: ['./professional.component.css']
})
export class ProfessionalComponent implements OnInit {

	

	title='Professional';
	show= false;
  disp=false;
  prog=false;
  proj=false;
  res=false;

  constructor() { }

  ngOnInit(): void {
  }

  toolclick()
  {
  	 this.show = !this.show


  }

  educlick()
  {
    this.disp= !this.disp
  }

  whyclick()
  {
    this.prog= !this.prog;
  }
  projclick()
  {
    this.proj= !this.proj;
  }

  resuclick()
  {
    this.res= !this.res;
  }
 

}
